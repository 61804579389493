var render = function () {
  var _vm$phone, _vm$formState, _vm$phone2, _vm$formState2, _vm$formState2$error, _vm$formState2$error$, _vm$formState2$error$2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "pos": "relative",
      "w": "100%",
      "mx": "auto",
      "bg": ['#FFF', '#FFF'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": ['0.5rem', '1.5rem'],
      "min-height": ['auto', '74vh']
    }
  }, [_c('Stepper', {
    attrs: {
      "margin-top": "16px",
      "margin-bottom": ['12px', '20px'],
      "steps": [{
        label: 'Pilih Program'
      }, {
        label: 'Informasi Pribadi'
      }, {
        label: 'Periksa',
        isCurrentStep: true
      }, {
        label: 'Pembayaran'
      }]
    }
  }), _c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "28px",
      "text-align": "center"
    }
  }, [_vm._v(" Periksa Pesanan ")]), _c('c-box', {
    attrs: {
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['0 12px', '30px'],
      "margin-top": ['22px', '20px']
    }
  }, [_c('ElementCheckOrderInfoProgram', {
    attrs: {
      "route-change-program": _vm.routeChangeProgram,
      "photo-url": _vm.productImage,
      "name": _vm.productName,
      "sub-name": _vm.productServiceName + ' ' + '(' + _vm.duration + ')',
      "more-info-url": _vm.handleShowDetailProgram,
      "price": _vm.subTotalPrice,
      "coupon-code": _vm.couponCode,
      "coupon-status": _vm.statusAppliedCoupon,
      "is-applying-coupon": _vm.isLoadingApplyCoupon,
      "has-phone-number": _vm.hasPhoneNumberBefore,
      "is-valid-phone": ((_vm$phone = _vm.phone) === null || _vm$phone === void 0 ? void 0 : _vm$phone.length) >= 1 ? _vm.isValidPhone && ((_vm$formState = _vm.formState) === null || _vm$formState === void 0 ? void 0 : _vm$formState.success) : true,
      "info-message-phone": ((_vm$phone2 = _vm.phone) === null || _vm$phone2 === void 0 ? void 0 : _vm$phone2.length) >= 1 ? (_vm$formState2 = _vm.formState) === null || _vm$formState2 === void 0 ? void 0 : (_vm$formState2$error = _vm$formState2.error) === null || _vm$formState2$error === void 0 ? void 0 : (_vm$formState2$error$ = _vm$formState2$error.issues) === null || _vm$formState2$error$ === void 0 ? void 0 : (_vm$formState2$error$2 = _vm$formState2$error$[0]) === null || _vm$formState2$error$2 === void 0 ? void 0 : _vm$formState2$error$2.message : null,
      "phone": _vm.phone,
      "coupon-detail": _vm.couponDetail
    },
    on: {
      "set-coupon-code": function setCouponCode(e) {
        _vm.couponCode = e.target.value;
        _vm.couponDetail.isValid = null;
      },
      "handle-click-check-coupon": _vm.handleApplyCoupon,
      "set-phone": function setPhone(e) {
        _vm.phone = e.target.value;
        _vm.isValidPhone = true;
      }
    }
  }), _c('c-box', {
    attrs: {
      "margin-top": "20px"
    }
  }, [_c('ElementCheckOrderInfoPrice', {
    attrs: {
      "price": _vm.subTotalPrice,
      "discount": _vm.discount,
      "final-price": _vm.totalPrice
    }
  })], 1)], 1), _c('c-flex', {
    attrs: {
      "padding": ['0 12px', '0'],
      "gap": ['4px', '16px'],
      "margin-top": ['16px', '20px']
    }
  }, [_c('BaseButton', {
    attrs: {
      "size": "large",
      "variant": "outlined",
      "w": "100%",
      "border-radius": "1000px"
    },
    on: {
      "click": _vm.handlePrevious
    }
  }, [_vm._v(" Kembali ")]), _c('BaseButton', {
    attrs: {
      "size": "large",
      "variant": "contained",
      "w": "100%",
      "border-radius": "1000px",
      "is-loading": _vm.isLoadingCheckout,
      "disabled": !_vm.formState.success
    },
    on: {
      "click": _vm.handleNext
    }
  }, [_vm._v(" Selanjutnya ")])], 1), _c('ModalCouponInvalid', {
    attrs: {
      "is-open": _vm.isOpenModalCouponInvalid
    },
    on: {
      "handleClose": function handleClose($event) {
        _vm.isOpenModalCouponInvalid = false;
      }
    }
  }), _c('ModalInfo', {
    attrs: {
      "for": "errorCannotPurchaseDifferentProgram",
      "with-button-close": false,
      "is-open": _vm.isOpenModalErrorProgramCannotPurchase
    },
    on: {
      "close": _vm.close
    }
  }), _c('ModalErrorProgramCannotPurchaseKonsultation', {
    attrs: {
      "is-open": _vm.isModalOpen.ModalErrorProgramCannotPurchaseKonsultation
    },
    on: {
      "handleClose": function handleClose($event) {
        _vm.isModalOpen.ModalErrorProgramCannotPurchaseKonsultation = false;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }